/**
 * Basic typography style for copy text
 */

body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 100%;
   vertical-align: baseline;
}
.kamikodeNavbar{
font-family: "Quicksand";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
