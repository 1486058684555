.szh-accordion__item {
    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
      color: #000000;
      background-color: transparent;
      border: none;
      background-color: white;
      transition: all 300ms ease-in-out;
      border-radius: 20px;
      font-size: 19px;
      margin-top: 10px;
      &:hover {
        background-color: #f8f6f6;
      }
    }

    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
      background-color: white;
      border-radius: 20px;
      margin-top: 10px;
      font-size: 14px;
    }

    &-panel {
      padding: 1rem;
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }

  .szh-accordion__item--expanded {
    .szh-accordion__item-btn {
       background-color: white;
       border-radius: 5px 5px 0 0;
    }
     .szh-accordion__item-content{
        margin-top: 0;
        border-radius: 0 0 25px 25px;
    }
   
    .chevron-down {
      transform: rotate(180deg);
    }
     
}