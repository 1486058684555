@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;600;700&display=swap');

@font-face {
    font-family: 'Quicksand';
    font-weight: 300; 
    font-style: normal; 
    src: local('Quicksand Light'), local('Quicksand-Light'), url('https://fonts.gstatic.com/s/quicksand/v26/6xKtdSZaM9iE8KbpRA_pgHYoSA.ttf') format('truetype');
}

body {
    font-family: 'Quicksand', sans-serif; /* Use Quicksand as the primary font with a sans-serif fallback */
}
