.primaryButton {
width: 132px;
height: 35px;
flex-shrink: 0;
background-color: $dark-blue ;
color: white;
border-radius: 23px;
text-align: center;
font-family: Quicksand;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: none;
}
.secondaryButton {
width: 194px;
height: 41px;
flex-shrink: 0;
background-color: $brand-color ;
color: $dark-blue;
border-radius: 23px;
text-align: center;
font-family: Quicksand;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: none;
transition: all 200ms ease-in-out;
cursor: pointer;

}
.secondaryButton:hover {
background-color: #ecae10;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}