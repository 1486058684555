.whatWeOffer {
  display: flex;
  height: 80vh;
  padding: 0px 51px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  
}

.wwoContainer {
  border-radius: 5px;
  background: var(--light-purple);
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  flex-shrink: 0;
  padding: 40px 56px;
  display: flex;
}

.wwoLeft {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 18px;
  height: 100%;
  max-height: 100%;
  justify-content: center;

  
}

.wwoRight {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.wwoTitle {
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.wwoSubTitle {
  font-size: 14px;
  padding-left: 20px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  line-height: 20px;
}

.bubbleContainer {
  display: flex;
  gap: 8.5px;
  flex-wrap: wrap;
  padding-left: 20px;
}

.wwoParagraph {
  padding-left: 20px;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media only screen and (max-width: 600px) {
.wwoContainer {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;

}
.whatWeOffer {
  padding: 0px 20px;
}
}
