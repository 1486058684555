// Regular font family
// @type List
$text-font-stack: 'Quicksand', 'Arial', 'Poppins', sans-serif !default;

// Code (monospace) font family
// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

// Copy text color
// @type Color
$text-color: #003047 !default;

$dark-blue: #003047 !default;
// Main brand color
// @type Color
$brand-color: #F4BE30 !default;

$dark-orange: #F48830 !default;
// Light grey
// @type Color
$light-grey: rgb(237, 237, 237) !default;

// Medium grey
// @type Color
$mid-grey: rgb(153, 153, 153) !default;

// Dark grey
// @type Color
$dark-grey: rgb(68, 68, 68) !default;

// @type Color

$light-purple: #F1F1FF !default;
$dark-purple: #494A7D !default;

// Export them here to be used globally
:root {
  --dark-blue: #003047;
  --brand-color: #F4BE30;
  --dark-orange: #F48830;
  --light-purple: #F1F1FF;
  --dark-purple: #494A7D;
}

// Container's maximum width
// @type Length
$max-width: 1180px !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;

// Relative or absolute URL where all assets are served from
// @type String
// @example scss - When using a CDN
//   $base-url: 'https://cdn.example.com/assets/';
$base-url: '/assets/' !default;
