.navBar {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    padding: 17px 0;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
}

ul {
    display: flex;
    flex-direction: row;
    gap: 59px;
    align-items: center;
    
}

.leftBar,
.rightBar {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    
}

.kamiLogo {
    height: 36px;
}

@media only screen and (max-width: 800px) {
 ul{
    gap:20px;
    flex-wrap: wrap;
    padding-left: 0;
 }
 .navBar{
    flex-wrap: wrap;
 }

 .leftBar,
.rightBar {
    justify-content: center;
}
}