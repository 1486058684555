.bubble{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    min-width: 100.553px;
    width: fit-content;
    height: 37px;
    flex-shrink: 0;
    padding: 9px;
    background-color: white;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 150ms ease-in-out;
    cursor: pointer;
}
.bubble:hover{
    background-color: rgb(248, 248, 248);
     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.bubbleText{
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
margin:0;
}

