.clientWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
@media only screen and (max-width: 600px) {
 .clientWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
}