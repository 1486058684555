.heroSection{
    display: flex;
    align-items: center;
    height: 607px;
    width: 100%;

}


.heroLeft{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding-left: 36px;
    gap: 32px;
}
.heroRight{
    flex:1;

    width: 100%;
    display: flex;
    justify-content: center;
    
}
.heroTitle{
    font-family: "Quicksand";
    font-size: 48px;
}
.heroImage{
    
    height: 100%;

}
.heroParagraph{
font-family: 'Quicksand';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
@media only screen and (max-width: 900px) {
 .heroSection{
    flex-direction: column;
}
.heroImage{
    display: none;
}
.heroLeft{
    padding-left: 0;

}
}